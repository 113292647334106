import customConfig from "../custom/config.json";
import { ConciergeConnectMicrositeConfig } from "../types/config";

let inMemoryConfig: ConciergeConnectMicrositeConfig;

export function getConfig(): ConciergeConnectMicrositeConfig {
  return (
    inMemoryConfig ||
    (customConfig as unknown as ConciergeConnectMicrositeConfig)
  );
}

export function setConfig(config: ConciergeConnectMicrositeConfig) {
  inMemoryConfig = config;
}
