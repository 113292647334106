import React, { useContext } from "react";
import { Title, Column, Paragraph } from "../../components/Atoms";
import { AppContext } from "../../handlers/AppContext";

export default function Welcome() {
  const { customer } = useContext(AppContext);

  return (
    <Column>
      <Title size={"h1"}>Welcome {customer?.firstName}</Title>
      <Paragraph align={"center"}>
        The store screen has been personalized for you.
      </Paragraph>
    </Column>
  );
}
