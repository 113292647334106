import { Home, NotFound, Welcome } from "./";
import { RouteInterface } from "../../types/Routes";

const LoginRoutes:Array<RouteInterface> = [
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/welcome',
    component: Welcome,
    exact: true
  },
  {
    path: '/404',
    component: NotFound,
    exact: true
  },

]

export default LoginRoutes