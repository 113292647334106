import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "@madmobile/concierge-connect-style-dictionary/dist/variables.css";
import "./style/index.scss";
import {
  setDefaultFontFaceDefinitions,
  setFontFaceDefinitions,
  ConciergeConnectStyleDictionary,
} from "@madmobile/concierge-connect-style-dictionary";
import { initCoreServicesAPI } from "./coreServices";
import { ConciergeConnectMicrositeConfig } from "./types/config";
import { getConfig } from "./utils/getConfig";
import { CoreServicesAPI } from "@madmobile/core-services-sdk";
import { BrowserRouter as Router } from "react-router-dom";

// These assets are populated at build-time.
import "./custom/fonts.css";
import "./custom/variables.css";

function renderApp(
  config: ConciergeConnectMicrositeConfig,
  coreServicesAPI: CoreServicesAPI
) {
  ReactDOM.render(
    <Router>
      <React.StrictMode>
        <App config={config} coreServicesAPI={coreServicesAPI} />
      </React.StrictMode>
    </Router>,
    document.getElementById("root")
  );
}

initCoreServicesAPI().then((coreServices) => {
  if (process.env.NODE_ENV === "development") {
    setDefaultFontFaceDefinitions();
    coreServices
      .callEndpoint({
        endpointName: "concierge-connect-microsite-config",
      })
      .then((res) => {
        const config = res.result as ConciergeConnectMicrositeConfig;
        renderApp(config, coreServices);

        const styleLink = document.createElement("link");
        styleLink.rel = "stylesheet";
        styleLink.href = config.assets.variables;
        document.head.appendChild(styleLink);

        fetch(config.assets.config)
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error(res.statusText);
          })
          .then((data: ConciergeConnectStyleDictionary) => {
            if (data?.asset?.font) {
              setFontFaceDefinitions(config.assets.baseUrl, data.asset.font);
            }
          });
      });
  } else {
    renderApp(getConfig(), coreServices);
  }
});
