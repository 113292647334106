import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useEnterToSubmit } from "../../hooks/useEnterToSubmit";
import {
  Title,
  Column,
  Paragraph,
  Spinner,
  Error,
} from "../../components/Atoms";
import {
  CcButton,
  CcTextInput,
} from "@madmobile/concierge-connect-react-components";
import { checkForEmpty } from "../../helpers/validators";
import { TextInput, EventDetail } from "../../types/TextInput";
import { AppContext } from "../../handlers/AppContext";
import errors from "../../text/errors.json";
import { useConciergeConnectGuestCheckIn } from "../../handlers/api";

export default function Guest() {
  const checkInGuest = useConciergeConnectGuestCheckIn();

  const { setCustomer, storeInfo } = useContext(AppContext);
  const [error, setError] = useState<string>("");

  const history = useHistory();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [customerName, setCustomerName] = useState<TextInput>({
    value: "",
    validationErrors: [],
  });

  const handleSubmitForm = async () => {
    if (!hasValidationErrors() && !isFetching && storeInfo.deviceId) {
      setIsFetching(true);
      try {
        await checkInGuest(storeInfo.deviceId, customerName.value);
        setCustomer({ firstName: customerName.value });
        history.push("/check-in/welcome");
      } catch {
        setError(errors.unableToReachCC);
      } finally {
        setIsFetching(false);
      }
    }
  };

  useEnterToSubmit(handleSubmitForm);

  const handleInput = (event: EventDetail) => {
    const { detail }: { detail: { value: string } } = event;
    const validationErrors: Array<string> = [];
    if (!!checkForEmpty(detail.value))
      validationErrors.push(checkForEmpty(detail.value)!);
    setCustomerName({ value: detail.value, validationErrors });
    return validationErrors;
  };

  const hasValidationErrors = () => {
    const errors = handleInput({
      detail: { name: "customer", value: customerName.value },
    });
    if (errors.length >= 1) return true;
    return false;
  };

  return (
    <Column>
      <Title size={"h1"}>Who should we say is here?</Title>

      <Paragraph align={"center"}>
        Please let us know your name so we can let our associates know you're
        here.
      </Paragraph>
      {error ? <Error text={error} /> : null}
      <CcTextInput
        name={"username"}
        label={"*Your name"}
        onChanged={handleInput}
        isPasswordInput={false}
        validationErrors={customerName.validationErrors}
        style={{ marginBottom: "20px" }}
      />
      <CcButton
        primary={true}
        btnSize={"sm"}
        onClick={() => handleSubmitForm()}
      >
        {isFetching ? <Spinner /> : "CHECK IN"}
      </CcButton>
    </Column>
  );
}
