import { CoreServicesAPI } from "@madmobile/core-services-sdk";
import axios from "axios";

function createEnvVarNotProvidedError(envVar: string) {
  return new Error(`Environment variable ${envVar} not provided.`)
}

export async function initCoreServicesAPI(): Promise<CoreServicesAPI> {
  const domain = process.env.REACT_APP_DOMAIN;
  if (!domain) throw createEnvVarNotProvidedError("REACT_APP_DOMAIN");

  const environment = process.env.REACT_APP_SANDBOX;
  if (!environment) throw createEnvVarNotProvidedError("REACT_APP_SANDBOX");
  
  const tenant = process.env.REACT_APP_TENANT;
  if (!tenant) throw createEnvVarNotProvidedError("REACT_APP_TENANT");

  const apiKey = process.env.REACT_APP_API_KEY;
  if (!apiKey) throw createEnvVarNotProvidedError("REACT_APP_API_KEY")

  let token: string;
  const coreServices = new CoreServicesAPI(
    {
      domain,
      environment,
      tenant,
      canaryTag: process.env.REACT_APP_CANARY
    },
    (options) => {
      return axios({
        method: options.method,
        headers: options.headers,
        url: options.url,
        data: options.data
      }).then((res) => res.data)
    },
    () => token
  );
  
  await coreServices.fetchPlatformToken(apiKey).then((res) => {
    token = res.access_token;
  })

  return coreServices;
}