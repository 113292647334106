// export const GetStoreInfo = (deviceId = "") => {
//   const [
//     countryCode,
//     brand,
//     storeNumber,
//     layout,
//     screenSize,
//     deviceNumber,
//     deviceName,
//   ] = deviceId.split("-", 7);
//   if (
//     !countryCode ||
//     !brand ||
//     !storeNumber ||
//     !layout ||
//     !screenSize ||
//     !deviceNumber ||
//     !deviceName
//   )
//     throw new Error(`Error parsing device id: ${deviceId || "undefined"}`);
//   return {
//     countryCode,
//     brand,
//     storeNumber,
//     layout,
//     screenSize,
//     deviceNumber,
//     deviceName,
//   };
// };

export const createEnvKey = (deviceId: string) => {
  return {
    deviceInfo: {
      id: deviceId,
    },
  };
};
