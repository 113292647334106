import React from "react";
import './Atoms.scss'

export const Divider = () => {
  return (
    <div className="divider">
      <span className="line"></span>
      <span className="text">Or</span>
      <span className="line"></span>
    </div>
  );
};
