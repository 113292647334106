import { VFC } from "react";
import "./Header.scss";

export const Header: VFC<{ logo: string }> = ({ logo }) => {
  return (
    <div className="header">
      <img src={logo} alt="" />
    </div>
  );
};
