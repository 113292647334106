import { Home, NotFound, Guest, Welcome } from "./";
import { RouteInterface } from "../../types/Routes";

const CheckinRoutes:Array<RouteInterface> = [
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/welcome',
    component: Welcome,
    exact: true
  },
  {
    path: '/guest',
    component: Guest,
    exact: true
  },
  {
    path: '/404',
    component: NotFound,
    exact: true
  },

]

export default CheckinRoutes