import React from "react";
import { Route } from "react-router-dom";
// Available routes for the app
import LoginRoutes from "./Login/Routes";
import CheckinRoutes from "./Check-in/Routes";

const availableRoutes = [
  {
    slash: "login",
    routes: LoginRoutes,
  },
  {
    slash: "check-in",
    routes: CheckinRoutes,
  },
];

export const RouteBuilder = () => {
  return (
    <>
      {availableRoutes.map(({ slash, routes }) =>
        routes.map(({ path, component, exact }) => {
          return (
            <Route key={path} path={`/${slash}${path}`} component={component} exact={exact} />
          );
        })
      )}
    </>
  );
};
