import React, { useMemo, useState } from "react";
import { RouteBuilder } from "./views/RouteBuilder";
import { Header } from "./components/Header/Header";
import { AppContext } from "./handlers/AppContext";
import { ConciergeConnectMicrositeConfig } from "./types/config";
import { Customer, StoreInfo } from "./types/AppContext";
import { CoreServicesAPI } from "@madmobile/core-services-sdk";
import { useQuery } from "./hooks/useQuery";

const App: React.VFC<{
  config: ConciergeConnectMicrositeConfig;
  coreServicesAPI: CoreServicesAPI;
}> = ({ config, coreServicesAPI }) => {
  const [customer, setCustomer] = useState<Customer | null>(null);
  const query = useQuery();

  const storeInfo = useMemo<StoreInfo>(
    () => ({
      deviceId: query.get("deviceId"),
      language: query.get("language"),
    }),
    [query]
  );

  return (
    <>
      <Header logo={config.assets.logo} />
      <AppContext.Provider
        value={{
          config,
          coreServicesAPI,
          customer,
          storeInfo,
          setCustomer,
        }}
      >
        <RouteBuilder />
      </AppContext.Provider>
    </>
  );
};

export default App;
