export const checkForEmpty = (value: string): string | null =>
  value === "" ? "This field is required." : null;

export const validateEmail = (value: string): string | null => {
  if(!value || value === '') return null;

  const emailRegex: RegExp = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  );
  return !emailRegex.test(value) ? "Please enter a valid email." : null;
};