import React from 'react'
import { Title, Column, Paragraph } from "../components/Atoms";
import errors from '../text/errors.json'

export default function NotFound() {
  return (
    <Column>
      <Title size={"h1"}>Not found</Title>
      <Paragraph align={"center"}>
      {errors.notFound}
      </Paragraph>
    </Column>
  )
}
