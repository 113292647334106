import { Customer } from "../types/AppContext";
import { createEnvKey } from "../helpers/storeinfo";
import { useCallback, useContext } from "react";
import axios, { AxiosResponse } from "axios";
import { AppContext } from "./AppContext";
import qs from "qs";

function useCustomerCommerceLogin() {
  const { config } = useContext(AppContext);
  return useCallback(
    async (creds: { username: string; password: string }) => {
      return axios
        .post<string, AxiosResponse<{ access_token: string }>>(
          config.other.authUrl,
          qs.stringify(creds),
          {
            headers: { "content-type": "application/x-www-form-urlencoded" },
          }
        )
        .then((res) => {
          return res.data.access_token;
        });
    },
    [config.other.authUrl]
  );
}

export function useConciergeConnectCustomerCheckIn() {
  const { coreServicesAPI } = useContext(AppContext);
  const loginCommerceCustomer = useCustomerCommerceLogin();

  return useCallback(
    (deviceId: string, credentials: { username: string; password: string }) => {
      return loginCommerceCustomer(credentials).then((accessToken) => {
        return coreServicesAPI
          .callEndpoint({
            endpointName: "concierge-connect-check-in",
            data: {
              envKey: createEnvKey(deviceId),
              customer: {
                accessToken,
              },
            },
          })
          .then((res) => res.result as Customer);
      });
    },
    [coreServicesAPI, loginCommerceCustomer]
  );
}

export function useConciergeConnectGuestCheckIn() {
  const { coreServicesAPI } = useContext(AppContext);

  return useCallback(
    (deviceId: string, firstName: string) => {
      return coreServicesAPI
        .callEndpoint({
          endpointName: "concierge-connect-check-in",
          data: {
            envKey: createEnvKey(deviceId),
            customer: {
              firstName,
            },
          },
        })
        .then((res) => res.result as Customer);
    },
    [coreServicesAPI]
  );
}

export function useConciergeConnectLogin() {
  const { coreServicesAPI } = useContext(AppContext);
  const loginCommerceCustomer = useCustomerCommerceLogin();

  return useCallback(
    (deviceId: string, credentials: { username: string; password: string }) => {
      return loginCommerceCustomer(credentials).then((accessToken) => {
        return coreServicesAPI
          .callEndpoint({
            endpointName: "concierge-connect-login",
            data: {
              envKey: createEnvKey(deviceId),
              customer: {
                accessToken,
              },
            },
          })
          .then((res) => res.result as Customer);
      });
    },
    [coreServicesAPI, loginCommerceCustomer]
  );
}
