import React, { useContext } from "react";
import { useHistory } from "react-router";
import { Title, Column, Paragraph } from "../../components/Atoms";
import { AppContext } from "../../handlers/AppContext";

export default function Welcome() {
  const history = useHistory();
  const { customer } = useContext(AppContext);

  return (
    <Column>
      {!customer && history.push("/check-in/")}
      <Title size={"h1"}>Welcome {customer?.firstName}</Title>
      <Paragraph align={"center"}>
        An associate will be with you shortly.
      </Paragraph>
    </Column>
  );
}
