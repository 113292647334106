import { useState, useContext, useEffect } from "react";
import { TextInput, EventDetail } from "../../types/TextInput";
import {
  Title,
  Error,
  Column,
  Paragraph,
  Spinner,
  Divider,
} from "../../components/Atoms";
import {
  CcButton,
  CcTextInput,
} from "@madmobile/concierge-connect-react-components";
import { checkForEmpty, validateEmail } from "../../helpers/validators";
import { useHistory } from "react-router-dom";
import { useEnterToSubmit } from "../../hooks/useEnterToSubmit";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useConciergeConnectCustomerCheckIn } from "../../handlers/api";
import { AppContext } from "../../handlers/AppContext";
import errors from "../../text/errors.json";

export default function Home() {
  const { width } = useWindowDimensions();
  const history = useHistory();

  const { storeInfo, setCustomer } = useContext(AppContext);
  const [error, setError] = useState<{ text: string; critical: boolean }>({
    text: "",
    critical: false,
  });
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<TextInput>({
    value: "",
    validationErrors: [],
  });
  const [password, setPassword] = useState<TextInput>({
    value: "",
    validationErrors: [],
  });

  const Validate: { [key: string]: any } = {
    email: {
      validators: [checkForEmpty, validateEmail],
      set: setUserEmail,
    },
    password: {
      validators: [checkForEmpty],
      set: setPassword,
    },
  };

  const handleInput = ({ detail: { name, value } }: EventDetail) => {
    const validationErrors: any = [];
    const input = Validate[name];
    input.validators.forEach((validator: any) => {
      if (!!validator(value)) {
        validationErrors.push(validator(value));
      }
    });
    input.set({ value, validationErrors });
    return validationErrors;
  };

  const hasValidationErrors = () => {
    handleInput({ detail: { name: "email", value: userEmail.value } });
    handleInput({ detail: { name: "password", value: password.value } });
    return (
      userEmail.value === "" ||
      userEmail.validationErrors.length >= 1 ||
      password.value === "" ||
      password.validationErrors.length >= 1
    );
  };

  useEffect(() => {
    if (!storeInfo.deviceId) {
      setError({ text: errors.noDeviceId, critical: true });
    }
  }, [storeInfo.deviceId]);

  const checkinCustomer = useConciergeConnectCustomerCheckIn();

  async function handleSubmitForm() {
    if (
      !hasValidationErrors() &&
      !error.critical &&
      !isFetching &&
      storeInfo.deviceId
    ) {
      setIsFetching(true);
      try {
        const customer = await checkinCustomer(storeInfo.deviceId, {
          username: userEmail.value,
          password: password.value,
        });
        setCustomer(customer);
        history.push("/check-in/welcome");
      } catch {
        setError({ text: errors.wrongUserOrPass, critical: false });
        setIsFetching(false);
      }
    }
  }

  useEnterToSubmit(handleSubmitForm);

  return (
    <Column>
      <Title size={"h1"}>Store Check-In</Title>
      <Paragraph align={"center"}>
        Please use your Ralph Lauren account to login or continue as a guest.
      </Paragraph>
      {error.text !== "" && <Error text={error.text} />}
      <CcTextInput
        autoFocus={true}
        name={"email"}
        label={"*Email Address"}
        onChanged={handleInput}
        isPasswordInput={false}
        validationErrors={userEmail.validationErrors}
        style={{
          marginBottom: width <= 375 ? "15px" : "20px",
          pointerEvents: error.critical && "none",
        }}
      />
      <CcTextInput
        name={"password"}
        label={"*Password"}
        isPasswordInput={true}
        onChanged={handleInput}
        validationErrors={password.validationErrors}
        style={{
          marginBottom: width <= 375 ? "20px" : "30px",
          pointerEvents: error.critical && "none",
        }}
      />
      <CcButton
        primary={true}
        btnSize={"sm"}
        style={{
          opacity: error.critical && 0.5,
          pointerEvents: error.critical && "none",
        }}
        onClick={() => handleSubmitForm()}
      >
        {isFetching ? <Spinner /> : "SIGN IN"}
      </CcButton>
      <Divider />
      <CcButton
        primary={false}
        btnSize={"sm"}
        style={{
          opacity: error.critical && 0.5,
          pointerEvents: error.critical && "none",
        }}
        onClick={() => history.push(`/check-in/guest`)}
      >
        CONTINUE AS GUEST
      </CcButton>
    </Column>
  );
}
